<template>
  <v-layout align-center justify-center>
    <div style="min-width: 500px; max-width: 900px">
      <h2 class="mb-5">Welcome to EVA Watchtower.</h2>

      <UserEditor
        v-bind:user="user"
        v-on:user-saved="userSaved"
        buttonText="Create"
        prompt="Create your first user account"
        v-bind:setup="true"
      ></UserEditor>
    </div>
  </v-layout>
</template>


<script>
import UserEditor from "./user-editor";
var userTemplate = function (seed) {
  return {
    emailAddress: null,
    firstName: null,
    lastName: null,
    id: 0,
    isActive: true,
    ...seed,
  };
};

export default {
  components: {
    UserEditor,
  },
  data() {
    return { user: userTemplate() };
  },
  methods: {
    async userSaved(user) {
      this.$store.dispatch("authenticate", this.user).then(
        async (user) => {
          if (user.token) {
            this.$router.push("/system");
          } else {
            this.setSnack({
              message: "Failed to authenticate",
              color: "error",
            });
          }
        },
        (err) => {
          this.setSnack({
            message: err,
            color: "error",
          });
        }
      );
    },
  },
};
</script>