<template>
  <v-form ref="userForm" v-model="valid" :disabled="!$isAdmin">
    <v-card style="max-width: 900px" class="pb-5" outlined>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ prompt }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="max-width: 900px">
        <v-text-field
          outlined
          v-model="user.firstName"
          label="First name"
          :rules="rules.firstName"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="user.lastName"
          label="Last name"
          :rules="rules.lastName"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="user.emailAddress"
          label="Email Address"
          :rules="rules.emailAddress"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="user.password"
          label="Password"
          :rules="user.id > 0 ? [] : rules.password"
          :required="user.id === 0"
        ></v-text-field>

        <v-select
          :items="roles"
          outlined
          v-model="user.flags"
          item-text="name"
          item-value="id"
          label="Role"
        ></v-select>

        <v-switch
          v-if="!setup"
          v-model="user.isActive"
          color="primary"
          label="Is active"
        ></v-switch>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          v-if="user.id > 0"
          class="v-btn-icon"
          color="secondary"
          :disabled="!$isAdmin"
          outlined
          @click="deleteUser"
        >
          <i class="nbi-icon nbi-trashcan error--text"></i>
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn class="primary" :disabled="!$isAdmin || !valid" @click.native="save">
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
      <confirm-dialog ref="confirm"></confirm-dialog>
    </v-card>
  </v-form>
</template>

<script>
import { PlatformAPI } from "../http";
import { mapMutations } from "vuex";

export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return { flags: 0 };
      },
    },
    setup: {
      type: Boolean,
      default: () => false,
    },
    allowDelete: {
      type: Boolean,
      default: () => true,
    },
    buttonText: {
      type: String,
      default: () => "Save",
    },
    prompt: {
      type: String,
      default: () => "User Details",
    },
  },
  data() {
    let that = this;
    return {
      roles: [
        { id: 0, name: "Regular" },
        { id: 1, name: "Admin" },
      ],
      valid: false,
      rules: {
        firstName: [(v) => !!v || "First Name is required"],
        lastName: [(v) => !!v || "Last Name is required"],
        flags: [(v) => !!v || "Role is required"],
        emailAddress: [
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Email Address is required",
        ],
        password: [
          (v) => {
            return !!v || "Password is required";
          },
        ],
      },
    };
  },
  methods: {
    ...mapMutations(["setSnack"]),
    async save() {
      this.$refs.userForm.validate();
      try {
        this.loading = true;

        let path = this.setup ? "setup/user" : `users/${this.user.id || ""}`;
        const response = await PlatformAPI.post(`/api/${path}`, this.user);

        this.loading = false;

        this.$emit("user-saved", response.data);
      } catch (err) {
        this.loading = false;

        if (err.response && err.response.status == 400) {
          let message = `Validation errors :\n `;

          message += Object.entries(err.response.data.errors)
            .map(([k, v], i) => v[0])
            .join("\n");
          this.setSnack({ message: message, color: "error" });
          console.log(err.response.data.errors);
        }
      }
    },
    async deleteUser() {
      const confirm = await this.$refs.confirm.open("Delete", "Are you sure?", {
        color: "red",
      });

      if (!confirm) return;

      var response = await PlatformAPI.delete(`/api/users/${this.user.id}`);
      this.$router.push("/users");
      this.setSnack({ message: "User deleted succesfully", color: "success" });
    },
  },
};
</script>
