import {
  TermColors
} from "../constants.js";

import {
  colorize,
  getSpacing
} from "../utils.js";

import cowsay from "./cowsay.js";
import ping from "./ping.js";
import tcpping from "./tcpping.js";
import ipinfo from "./ipinfo.js";

const SystemCommands = [
  cowsay,
  ping,
  tcpping,
  ipinfo,
  {
    id: "exit",
    args: 0,
    description: 'close the current terminal',
    async exec(term, _args, vue) {
      vue.$emit('terminal-closed');
    },
  },
  {
    id: "help",
    args: 0,
    async exec(term, _args) {
      term.writeln(
        "This simulated terminal will execute certain commands from within the context of an organization unit (store)."
      );
      term.writeln("");
      term.writeln("available commands:");
      // Add 3 tabs for spacing. Align each description to the first command description
      const firstCommandSpacing = SystemCommands[0].id.length + 12;
      for (const {
          id,
          description
        } of SystemCommands) {
        if (id === "help") continue;

        term.writeln(
          "\t" +
          colorize(TermColors.Green, id) +
          getSpacing(firstCommandSpacing - id.length) +
          description
        );
      }
    },
  },
];

export async function exec(userInput, term, vue, device) {
  const [input, ...args] = userInput.split(/\s+/);
  const command = SystemCommands.find((c) => c.id === input);
  if (!command) {
    return false;
  }

  if (args.length > 0) {
    if (command.args === 0) {
      term.writeln(colorize(TermColors.Red, `${command.id} does not accept arguments`));
      return true;
    }

    if (command.args === -1) {
      // Accepts 1 or more
      await command.exec(term, args, vue, device);
      return true;
    }

    if (command.args !== args.length) {
      term.writeln(colorize(TermColors.Red, "wrong number of arguments"));
      term.writeln(`usage: ${command.usage}`);
    } else {
      await command.exec(term, args, vue, device);
    }
  } else {
    if (command.args === 0) {
      await command.exec(term, args, vue, device);
    } else {
      term.writeln(colorize(TermColors.Red, "wrong arguments"));
      term.writeln(`usage: ${command.usage}`);
    }
  }
  return true;
}