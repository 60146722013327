var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-overlay',{staticStyle:{"z-index":"1100"},attrs:{"absolute":true,"value":_vm.offline || _vm.loading}},[(_vm.offline)?_c('v-card',{staticClass:"pa-5",attrs:{"light":""}},[_c('v-card-text',[_c('h3',[_vm._v("Disconnected")]),_c('p',[_vm._v("The connection to the server was lost. Please try again after a cup of coffee")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"primary",attrs:{"outlined":""},on:{"click":_vm.connectUIHub}},[_vm._v("Reconnect")])],1)],1):_vm._e(),(_vm.loading)?_c('v-card',{staticClass:"pa-5",attrs:{"light":""}},[_c('v-card-text',[_c('div',[_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","size":20,"width":2,"color":"primary"}}),_vm._v(" Digging up details... ")],1)])],1):_vm._e()],1),_c('l-map',{ref:"map",attrs:{"center":_vm.center,"maxZoom":10,"minZoom":2,"options":{
      preferCanvas: true,
    }}},[_c('l-control-fullscreen',{attrs:{"position":"topleft","options":{
        title: { false: 'Toggle Fullscreen', true: 'Exit Fullscreen' },
      }}}),_c('l-tile-layer',{attrs:{"url":_vm.url,"noWrap":true,"options":{
        bounds: [
          [-90, -180],
          [90, 180],
        ],
      }}}),_c('v-marker-cluster',{ref:"clusterOnline",attrs:{"options":_vm.clusterOptions,"averageCenter":true,"ignoreHidden":true}},_vm._l((_vm.onlineOrganizationUnits),function(ou){return _c('organization-unit',{key:ou.id,attrs:{"ou":ou}})}),1),_c('v-marker-cluster',{ref:"clusterOffline",attrs:{"options":_vm.clusterOptions,"averageCenter":true,"ignoreHidden":true}},_vm._l((_vm.offlineOrganizationUnits),function(ou){return _c('organization-unit',{key:ou.id,attrs:{"ou":ou,"pulse":true}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }