<template>
  <div style="max-width: 900px">
    <h2 class="mb-5">
      Users
      <v-btn
        v-if="$isAdmin"
        outlined
        class="v-btn-icon float-right"
        color="secondary"
        :to="{ name: 'user', params: { id: 'new' } }"
      >
        <i class="nbi-icon nbi-plus"></i>
      </v-btn>
    </h2>

    <v-card class="pb-5" outlined>
      <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
      <v-simple-table v-if="!loading" class="row-pointer">
        <thead>
          <tr>
            <th class="text-left">Active</th>
            <th class="text-left">Email</th>
            <th class="text-left">First Name</th>
            <th class="text-left">Last Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td style="text-align: center">
              <span v-if="user.isActive" class="success--text">Yes</span>
              <span v-else class="secondary--text">No</span>
            </td>
            <td>
              <router-link :to="{ name: 'user', params: { type: 'id', id: `${user.id}` } }">
                {{ user.emailAddress }}
              </router-link>
            </td>

            <td>{{ user.firstName }}</td>
            <td>{{ user.lastName }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { PlatformAPI } from "../http";
export default {
  data() {
    return {
      users: [],
      loading: false,
    };
  },

  async mounted() {
    await this.getUsers();
  },

  methods: {
    async getUsers() {
      try {
        this.loading = true;
        const response = await PlatformAPI.get(`/api/users`);
        if (response && response.data) this.users = response.data;
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
