<template>
  <v-container fluid>
    <h2 class="mb-5">Invoices</h2>

    <!-- <h1 class="headline">
      <span class="text--secondary">Invoices /&nbsp;</span>Mix & Match
    </h1> -->

    <v-card
      class="mb-5"
      outlined
    >

      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title>Selected Shipments</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          outlined
          color="secondary"
          :to="{ name: 'backend', params: { id: 'new' } }"
          class="v-btn-icon"
        >
          <i class="nbi-icon nbi-plus"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</v-card-text>
      <v-data-table
        :headers="headers"
        :items="shipments"
        :items-per-page="5"
      ></v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="secondary"
        >Cancel</v-btn>

        <v-btn
          class="primary"
          @click.native="save"
        >Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-card>
      <v-card-text>
        <p>This is some default text Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
        <v-divider></v-divider>

        <div class="square mt-5">
          <div class="grey">grey</div>
          <div class="grey lighten-1">grey lighten 1</div>
          <div class="grey lighten-2">grey lighten 2</div>
          <div class="grey lighten-3">grey lighten 3</div>
          <div class="grey lighten-4">grey lighten 4</div>
        </div>
        <div class="square">
          <div class="blue">blue</div>
          <div class="blue lighten-1">blue lighten 1</div>
          <div class="blue lighten-2">blue lighten 2</div>
          <div class="blue lighten-3">blue lighten 3</div>
          <div class="blue lighten-4">blue lighten 4</div>
        </div>
        <div class="square">
          <div class="red">red</div>
          <div class="red lighten-1">red lighten 1</div>
          <div class="red lighten-2">red lighten 2</div>

        </div>
        <div class="square">
          <div class="orange">orange</div>
          <div class="yellow">yellow</div>
          <div class="cyan">cyan</div>
          <div class="green">green</div>

        </div>
      </v-card-text>
    </v-card>

    <v-card
      outlined
      class="mb-5"
      style="padding: 30px;"
    >
      <div class="my-2">

        <v-btn
          class="mx-2"
          color="primary"
        >Primary</v-btn>

        <v-btn
          class="mx-2"
          color="primary"
          disabled
        >Primary</v-btn>

        <v-btn
          class="mx-2"
          text
          color="primary"
        >default</v-btn>

      </div>
      <div class="my-2">
        1
        <v-btn
          class="mx-2"
          color="secondary"
          outlined
        >Secondary</v-btn>

        <v-btn
          class="mx-2"
          color="secondary"
          outlined
          disabled
        >Secondary</v-btn>

      </div>

      <div class="my-2">
        <v-btn
          class="mx-2 v-btn-icon"
          outlined
          color="secondary"
        >
          <i class="nbi-icon nbi-eva"></i>
        </v-btn>

        <v-btn
          class="mx-2 v-btn-icon"
          outlined
          color="secondary"
        >
          <i class="nbi-icon nbi-pencil"></i>
        </v-btn>
        <v-btn
          class="mx-2 v-btn-icon"
          outlined
          color="secondary"
          disabled
        >
          <i class="nbi-icon nbi-pencil"></i>
        </v-btn>

      </div>

      <div class="my-2">

        <v-btn-toggle>
          <v-btn
            outlined
            color="secondary"
          >
            <i class="nbi-icon nbi-direction-start"></i>

          </v-btn>

          <v-btn
            outlined
            color="secondary"
          >
            <i class="nbi-icon nbi-direction-previous"></i>
          </v-btn>

          <v-btn
            outlined
            color="secondary"
          >
            <i class="nbi-icon nbi-direction-next"></i>
          </v-btn>

          <v-btn
            outlined
            color="secondary"
          >
            <i class="nbi-icon nbi-direction-end"></i>
          </v-btn>
        </v-btn-toggle>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="primary"
          @click.native="submit()"
        >Login</v-btn>

        <v-btn
          class="primary"
          @click.native="submit()"
        >A very Long Button Text</v-btn>
      </v-card-actions>

      <div class="my-5">
        <v-btn
          class="mx-2"
          color="error"
        >Danger</v-btn>
        <v-btn
          class="mx-2"
          outlined
          color="error"
          depressed
        >Danger</v-btn>
        <v-btn
          class="mx-2"
          text
          color="error"
        >Danger</v-btn>
      </div>
      <div class="my-2">
        <v-btn
          class="mx-2"
          depressed
          color="warning"
        >Warning</v-btn>
        <v-btn
          class="mx-2"
          outlined
          color="warning"
          depressed
        >Danger</v-btn>
        <v-btn
          class="mx-2"
          text
          color="warning"
        >Danger</v-btn>
      </div>
    </v-card>

    <v-card
      outlined
      class="mb-5"
      style="padding: 30px;"
    >
      <form>
        <v-text-field
          label="Input value"
          outlined
        ></v-text-field>
        <br /><br /><br /><br />
        <v-text-field
          label="Input value"
          outlined
          class="testfield"
        ></v-text-field>
        <br /><br /><br /><br />
        <v-text-field outlined></v-text-field>

        <v-checkbox
          color="primary"
          label="Remember me next time"
        ></v-checkbox>
        <v-select
          :items="items"
          outlined
          label="Label"
        ></v-select>
        <v-select
          label="Label"
          color="primary"
          multiple
          chips
          deletable-chips
          :items="items"
          outlined
        ></v-select>
      </form>
    </v-card>

    <v-card
      outlined
      class="mb-5"
      style="padding: 30px;"
    >
      <div
        class="v-dialog"
        style="width: 450px"
      >
        <v-card>
          <v-card-title>
            <h1 class="headline">Did you know?</h1>
          </v-card-title>

          <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              outlined
              color="primary"
            >Delete</v-btn>
            <v-btn
              depressed
              outlined
              color="primary"
            >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-card>

    <v-card outlined>
      <v-card-text>
        <v-progress-linear
          color="primary"
          indeterminate
          style="margin: 20px;"
        ></v-progress-linear>
        <v-progress-circular
          color="success"
          indeterminate
          style="margin: 20px;"
        ></v-progress-circular>Please wait...
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style lang="scss">
.square {
  align-items: left;
}

.square div {
  display: inline-block;
  width: 100px;
  height: 100px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
export default {
  data() {
    return {
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      headers: [
        {
          text: "Prouct Name",
          value: "productName",
        },
        {
          text: "Custom ID",
          value: "customId",
        },
        {
          text: "Supplier ID",
          value: "supplierId",
        },
        {
          text: "Quantity Delivered",
          value: "delivered",
          align: "right",
        },
        {
          text: "Quantity on Invoice",
          value: "onInvoice",
          align: "right",
        },
        {
          text: "Unit Price",
          value: "unitPrice",
          align: "right",
        },
        {
          text: "Amount Delivered",
          value: "amountDelivered",
          align: "right",
        },
        {
          text: "Amount on Invoice",
          value: "amountOnInvoice",
          align: "right",
        },
      ],
      shipments: [
        {
          id: 1,
          productName: "Maxi Cosi Rock",
          customId: "BHU890",
          supplierId: "1234QWER",
          delivered: 4,
          onInvoice: 4,
          unitPrice: 12.0,
          amountDelivered: 234.0,
          amountOnInvoice: 432.0,
        },
        {
          id: 2,
          productName: "Maxi Cosi Rock",
          customId: "BHU890",
          supplierId: "1234QWER",
          delivered: 4,
          onInvoice: 4,
          unitPrice: 12.0,
          amountDelivered: 234.0,
          amountOnInvoice: 432.0,
        },
        {
          id: 3,
          productName: "Maxi Cosi Rock",
          customId: "BHU890",
          supplierId: "1234QWER",
          delivered: 4,
          onInvoice: 4,
          unitPrice: 12.0,
          amountDelivered: 234.0,
          amountOnInvoice: 432.0,
        },
      ],
    };
  },
};
</script>


