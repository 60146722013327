<template>
  <v-form ref="backendForm" v-model="valid" :disabled="!$isAdmin">
    <v-card-text>
      <v-text-field
        outlined
        v-model="backend.name"
        label="Name"
        required
        :rules="rules.name"
      ></v-text-field>

      <v-text-field
        outlined
        v-model="backend.endPoint"
        label="Endpoint"
        required
        :rules="rules.endpoint"
      ></v-text-field>
      <v-text-field
        outlined
        v-model="backend.apiKey"
        :rules="rules.apiKey"
        :type="showKeys ? 'text' : 'password'"
        label="API Key"
      ></v-text-field>

      <div v-if="backend.id > 0 && !simple">
        <v-switch inset v-model="backend.isEnabled" color="primary" label="Enabled"></v-switch>
        <v-text-field
          v-model="backend.primaryAccessToken"
          readonly
          :type="showKeys ? 'text' : 'password'"
          placeholder="<Will be generated on save>"
          label="Primary Access Token"
        ></v-text-field>
        <v-text-field
          v-model="backend.secondaryAccessToken"
          placeholder="<Will be generated on save>"
          label="Secondary Access Token"
          readonly
          :type="showKeys ? 'text' : 'password'"
        ></v-text-field>
        <v-btn small @click="showKeys = !showKeys" color="secondary" outlined>
          <i
            class="nbi-icon small"
            :class="{ 'nbi-eye': !showKeys, 'nbi-eye-slash': showKeys }"
          ></i>
          {{ showKeys ? "Hide" : "Show" }} Keys
        </v-btn>
      </div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        v-if="backend.id > 0 && allowDelete && $isAdmin"
        class="v-btn-icon"
        color="secondary"
        outlined
        @click="deleteBackend"
      >
        <i class="nbi-icon nbi-trashcan error--text"></i>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined class="primary" :disabled="!$isAdmin || !valid" @click="save">
        {{ buttonText }}
      </v-btn>
    </v-card-actions>
    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-form>
</template>

<script>
import { PlatformAPI } from "../http";
import { mapMutations } from "vuex";

export default {
  props: {
    backend: {
      type: Object,
      default: () => {
        return {};
      },
    },
    simple: {
      type: Boolean,
      default: () => false,
    },

    allowDelete: {
      type: Boolean,
      default: () => true,
    },
    buttonText: {
      type: String,
      default: () => "Save",
    },
  },
  data() {
    return {
      valid: false,
      rules: {
        name: [(v) => !!v || "Name is required"],
        endpoint: [(v) => !!v || "Endpoint is required"],
        apiKey: [
          (v) => !!v || this.backend.id > 0 || "API key is required",
          // (v) => (v && v.length == 64) || "Invalid length, needs 64 characters",
        ],
      },
      showKeys: false,
    };
  },

  methods: {
    ...mapMutations(["setSnack"]),
    async refreshKey(keyIndex) {
      const response = await PlatformAPI.post(`/api/backends/${this.backend.id || ""}/reset-keys`, {
        key: keyIndex,
      });
      43;
      if (keyIndex == 0) {
        this.backend.primaryAccessToken = response.data.accessToken;
      } else {
        this.backend.secondaryAccessToken = response.data.accessToken;
      }
    },

    async save() {
      this.$refs.backendForm.validate();
      try {
        this.loading = true;

        const response = await PlatformAPI.post(
          `/api/backends/${this.backend.id || ""}`,
          this.backend
        );

        this.loading = false;

        this.$emit("backend-saved", response.data);
      } catch (err) {
        this.loading = false;
        window.alert(err);
        console.log(err);
      }
    },

    async deleteBackend() {
      const confirm = await this.$refs.confirm.open("Delete", "Are you sure?", {
        color: "red",
      });

      if (!confirm) return;

      const confirm2 = await this.$refs.confirm.open("Delete", "Are you really sure?", {
        color: "red",
      });

      if (!confirm2) return;

      try {
        var response = await PlatformAPI.delete(`/api/backends/${this.backend.id}`);

        this.$emit("backend-deleted", this.backend.id);

        this.setSnack({
          message: "Backend deleted",
          color: "success",
        });

        this.$router.push({
          name: "backends",
        });
      } catch (e) {
        this.setSnack({
          message: e,
          color: "error",
        });
      }
    },
  },
};
</script>
