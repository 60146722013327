<template>
  <!--  -->
  <v-snackbar :color="snackbar.color" absolute bottom text v-model="show">
    <span class="white--text">{{ snackbar.message }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click.native="show = false">
        <i class="white--text nbi-icon nbi-xmark"></i>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      show: false,
    };
  },

  computed: {
    ...mapState(["snackbar"]),
  },
  created: function () {
    this.$store.watch(
      (state) => state.snackbar,
      () => {
        this.show = this.$store.state.snackbar.message !== "";
      }
    );
  },
};
</script>
