<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <h2 class="mb-5" style="display: flex; align-items: center;">
          <i class="nbi-icon nbi-warning pa-3 warning--text" style="font-size:48px;"></i>Offline
        </h2>
        <v-card>
          <v-card-text style="display: flex; align-items: center;" class="pa-5">
            Could not connect to the EVA Watchtower platform. Check your internet connection and refresh the page
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>