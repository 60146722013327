<template>
  <div>
    <router-link style="text-decoration: none" class="primary--text" to="/users">
      <i class="nbi-icon nbi-arrow-left"></i>
      Back to list
    </router-link>
    <h2 class="my-5">{{ title }}</h2>
    <UserEditor v-bind:user="user" v-on:user-saved="userSaved"></UserEditor>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { PlatformAPI } from "../http";
import UserEditor from "./user-editor";
var userTemplate = function (seed) {
  return {
    emailAddress: null,
    firstName: null,
    lastName: null,
    id: null,
    isActive: true,
    flags: 0,
    ...seed,
  };
};

export default {
  components: {
    UserEditor,
  },
  data() {
    return {
      loading: false,
      user: userTemplate(),
    };
  },
  computed: {
    title() {
      return this.$route.params["id"] == "new" ? "Add User" : "Edit User";
    },
  },
  mounted() {
    if (this.$route.params["id"] != "new") {
      var id = this.$route.params["id"];
      this.getUser(id);
    }
  },

  methods: {
    ...mapMutations(["setSnack"]),
    async userSaved(user) {
      this.$router.push("/users");
      this.setSnack({ message: "User saved succesfully", color: "success" });
    },
    async getUser(id) {
      const response = await PlatformAPI.get(`/api/users/${id}`);
      this.user = response.data;
    },
  },
};
</script>
