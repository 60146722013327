<template>
  <div style="max-width: 900px">
    <h2 class="mb-5">System Info</h2>
    <v-card class="mb-5" outlined>
      <v-card-text>
        <v-row class="text-left">
          <v-col class="text-left pa-1" cols="3" style="color: #6b757c">Version</v-col>
          <v-col class="pa-1">
            <code>{{ info.version }}</code>
          </v-col>
        </v-row>
        <v-row class="text-left">
          <v-col class="text-left pa-1" cols="3" style="color: #6b757c">Backends</v-col>
          <v-col class="pa-1">
            <code>{{ info.backends }}</code>
          </v-col>
        </v-row>
        <v-row class="text-left">
          <v-col class="text-left pa-1" cols="3" style="color: #6b757c">Organization Units</v-col>
          <v-col class="pa-1">
            <code>{{ info.organizationUnits }}</code>
          </v-col>
        </v-row>
        <v-row class="text-left">
          <v-col class="text-left pa-1" cols="3" style="color: #6b757c">Devices</v-col>
          <v-col class="pa-1">
            <code>{{ info.totalDevices }}</code>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <h2 class="mb-5">Settings</h2>
    <v-card class="mb-5" outlined>
      <v-card-text>
        <i>Coming soon...</i>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { PlatformAPI } from "../http";
export default {
  data() {
    return { info: {} };
  },
  mounted() {
    this.loadInfo();
  },
  methods: {
    loadInfo() {
      PlatformAPI.get("/api/info").then(async (response) => {
        this.info = response.data;
      });
    },
  },
};
</script>
