import executor from "./commandexecutor";


const cowsay = {
  id: "tcpping",
  args: 2,
  description: "perform a tcp connect to a device on the local network",
  usage: "tcpping [hostname or ip ...] [port]",
  async exec(term, args, vue, device) {

    let req = {
      organizationUnitId: 2,
      backendId: 1,
      deviceId: device.id,
      command: "tcpping",
      address: args[0],
      port: args[1],
    };
    await executor.execute(term, req);

  },
};

export default cowsay;