import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);
import colors from 'vuetify/lib/util/colors'

// export default new Vuetify({});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#007AFF',
        secondary: '#D5DADF',
        success: '#34C759',
        error: '#FF3B30',

      },
    }, options: {
      customProperties: true
    }
  },

});



