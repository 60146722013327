import {
  PlatformAPI
} from "@/http";

const executor = {

  async execute(term, req) {
    try {
      const response = await PlatformAPI.post("/api/command/execute", req);

      if (response.data) {
        if (response.data.success)
          term.writeln(response.data.output);
        else
          term.writeln(response.data.output || response.data.message);
      } else {
        term.writeln("an unknown error occured");
      }
    } catch (err) {
      term.writeln("an error occured : " + err);
      console.log(err);
    }
  }

};

export default executor;