import executor from "./commandexecutor";

const cowsay = {
  id: "ping",
  args: 1,
  description: "ping a device on the local network",
  usage: "ping [hostname or ip ...]",
  async exec(term, args, vue, device) {

    let req = {
      deviceId: device.id,
      command: "ping",
      address: args[0],
    };
    await executor.execute(term, req);

  },
};

export default cowsay;