<template>
  <div style="max-width: 900px">
    <router-link style="text-decoration: none" class="primary--text" to="/system">
      <i class="nbi-icon nbi-arrow-left"></i>
      Back to overview
    </router-link>

    <h2 class="my-5">
      <span class="page-title">Device Details</span>
      <v-btn
        plain
        color="secondary"
        class="v-btn-icon float-right"
        @click="startTerminal"
        :disabled="device.state != 'connected'"
      >
        <i class="nbi-icon nbi-terminal mx-2" style="font-size: 22px"></i>
        Open terminal
      </v-btn>
      <!-- <v-btn @click="download">Download</v-btn> -->
    </h2>

    <device-terminal
      v-bind:device="device"
      v-bind:startTerminalTrigger="showTerminalDialog"
      v-on:terminal-closed="terminalDialogClosed"
    ></device-terminal>

    <v-card outlined v-if="device">
      <v-card-title>
        <i class="nbi-icon nbi-sentinel" style="font-size: 4rem"></i>
        <p class="ml-3 mt-5">{{ device.name }}</p>
      </v-card-title>
      <v-card-subtitle>
        <div v-if="device.state" :class="stateColor(device)">
          {{ device.state.charAt(0).toUpperCase() + device.state.slice(1) }}
        </div>
        <div v-if="device.lastConnected">
          Connected since {{ formatDateTime(device.lastConnected) }}
        </div>
        <div style="display: flex; align-items: center">
          <span ref="device-id">{{ device.id }}</span>
          <v-btn text icon @click="copyElementText('device-id')">
            <i class="nbi-icon nbi-duplicate small ml-1"></i>
          </v-btn>
        </div>
      </v-card-subtitle>

      <v-divider></v-divider>
      <div>
        <v-form ref="form" v-model="valid" :disabled="!$isAdmin">
          <v-card-text>
            <v-text-field
              label="Name"
              outlined
              v-model="device.name"
              :rules="rules.name"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-title>This device will handle requests for</v-card-title>

          <v-card-text>
            <p>
              <v-switch inset v-model="globalDevice" label="All backends">></v-switch>
            </p>
            <v-select
              v-if="!globalDevice"
              :items="backends"
              item-text="name"
              item-value="id"
              outlined
              label="Select the backend"
              v-model="selectedBackendId"
              clearable
              @change="loadOrganizationUnits"
              :disabled="device.id == null"
              :rules="rules.backendId"
            ></v-select>
            <v-switch
              inset
              v-if="!globalDevice && this.selectedBackendId"
              v-model="device.isFallback"
              label="All organization units"
            ></v-switch>
            <v-autocomplete
              v-if="!device.isFallback && !globalDevice"
              label="Attached to"
              clearable
              outlined
              v-model="device.organizationUnitId"
              no-data-text="No Organization Units found"
              :items="organizationUnits"
              item-value="id"
              :item-text="formatOUName"
              :rules="rules.organizationUnitId"
            ></v-autocomplete>
          </v-card-text>

          <v-card-title>Optional Modules</v-card-title>
          <v-card-text>
            <span v-if="!canOffline">
              <i class="nbi-icon nbi-warning warning--text"></i>
              Offline mode can only be enabled when a single Organization Unit is selected
            </span>

            <!-- <v-checkbox
              v-if="canOffline"
              v-model="device.offlineEnabled"
              :disabled="!canOffline"
              label="Offline Functionality"
            ></v-checkbox> -->

            <v-switch
              inset
              v-model="device.offlineEnabled"
              v-if="canOffline"
              :disabled="!canOffline"
              color="primary"
              label="Offline Functionality"
            ></v-switch>

            <v-text-field
              v-if="canOffline"
              ref="offlineApiKey"
              outlined
              :type="showApiKey ? 'text' : 'password'"
              label="API Key"
              v-model="device.offlineApiKey"
              :disabled="!device.offlineEnabled"
              :rules="device.offlineEnabled ? rules.offlineApiKey : []"
              append-icon="nbi-icon nbi-duplicate"
              @click:append="copyElementText('offlineApiKey')"
            ></v-text-field>

            <v-btn
              v-if="canOffline"
              small
              @click="showApiKey = !showApiKey"
              :disabled="!device.offlineApiKey"
              color="secondary"
              outlined
            >
              <i
                class="nbi-icon small"
                :class="{ 'nbi-eye': !showApiKey, 'nbi-eye-slash': showApiKey }"
              ></i>
              {{ showApiKey ? "Hide" : "Show" }} Key
            </v-btn>
          </v-card-text>

          <v-card-actions style="justify-content: space-between">
            <div>
              <v-btn
                v-if="device.id"
                :disabled="!$isAdmin"
                class="v-btn-icon"
                color="secondary"
                outlined
                @click="deleteDevice"
              >
                <i class="nbi-icon nbi-trashcan error--text"></i>
              </v-btn>
            </div>
            <v-btn
              outlined
              class="primary my-2"
              :disabled="!$isAdmin || !valid"
              @click="saveDevice"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </div>
      <confirm-dialog ref="confirm"></confirm-dialog>
    </v-card>
  </div>
</template>
<script>
import { PlatformAPI } from "../http";
import { mapMutations } from "vuex";
import DeviceTerminal from "./device-terminal";

function downloadFile(file) {
  // Create a link and set the URL using `createObjectURL`
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = file; //URL.createObjectURL(file);
  link.download = file.name;

  // It needs to be added to the DOM so it can be clicked
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait
  // a little while before removing it.
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);
}

export default {
  components: { DeviceTerminal },
  data() {
    return {
      device: {},
      backends: [],
      organizationUnits: [],
      globalDevice: false,
      selectedBackendId: null,
      showTerminalDialog: false,
      valid: false,
      showApiKey: false,
      rules: {
        name: [(v) => !!v || "Name is required"],
        offlineApiKey: [(v) => !!v || "API Key is required"],
        backendId: [(v) => !!v || this.globalDevice || "Backend is required"],
        organizationUnitId: [
          (v) =>
            !!v || this.globalDevice || this.device.isFallback || "Organization Unit is required",
        ],
      },
    };
  },
  async mounted() {
    await this.loadData();
  },

  computed: {
    canOffline() {
      return !this.deviceToSave.isFallback;
    },

    deviceToSave() {
      return {
        ...this.device,
        backendId: (!this.globalDevice && this.selectedBackendId) || null,
        isFallback: this.globalDevice ? true : this.device.isFallback,
        organizationUnitId:
          !this.globalDevice && !this.device.isFallback ? this.device.organizationUnitId : null,
      };
    },
  },
  methods: {
    ...mapMutations(["setSnack"]),

    // async download() {
    //   var response = await PlatformAPI.post(`/api/devices/${this.device.id}/download-log`, {});

    //   var fileUrl = `${PlatformAPI.defaults.baseURL}/api/devices/${this.device.id}/download/${response.data.id}`;
    //   console.log(fileUrl);

    //   downloadFile(fileUrl);

    // },
    startTerminal() {
      this.showTerminalDialog = true;
    },
    terminalDialogClosed() {
      console.log("close");
      this.showTerminalDialog = false;
    },
    copyElementText(el) {
      var e = this.$refs[el];

      navigator.clipboard.writeText(e.value || e.innerText).then(
        () => {
          this.setSnack({
            message: "Copied to clipboard",
            color: "success",
          });
        },
        (err) => {
          this.setSnack({
            message: "Error copying to clipboard : " + err,
            color: "error",
          });
        }
      );
    },

    async saveDevice() {
      try {
        await PlatformAPI.post(`/api/devices/${this.device.id}`, this.deviceToSave);

        this.setSnack({
          message: "Device saved",
          color: "success",
        });
      } catch (e) {
        this.setSnack({
          message: e,
          color: "error",
        });
      }
    },
    stateColor(item) {
      //if (item.state == "relayed" && item.isAuthorized) return "blue--text";
      if (item.state == "connected") return "green--text";

      if (item.state == "identifying") return "yellow--text";
      // if (item.state == "disconnected") return "red--text";
      return null;
    },
    async lookupExistingDevice() {
      try {
        const response = await PlatformAPI.post(`/api/onboarding/lookup`, {
          serialNumber: this.device.serialNumber,
        });

        if (response.status == 200) {
          this.device.managementId = response.data.id;
          this.device.created_ts = response.data.created_ts;
          this.device.status = response.data.status;
        }
      } catch (err) {
        if (err.response.status == 404)
          this.device = {
            serialNumber: this.device.serialNumber,
            managementId: null,
          };
      } finally {
        this.lookupDone = true;
      }
    },

    async loadData() {
      this.item = { id: this.$route.params["id"] };

      if (!this.item) return;

      let response = await PlatformAPI.get("/api/backends");
      this.backends = response.data;

      response = await PlatformAPI.get(`/api/devices/${this.item.id}`);
      if (response && response.data) {
        this.device = response.data;

        this.selectedBackendId =
          this.device.backendId ||
          (this.device.organizationUnit && this.device.organizationUnit.backendId);

        this.globalDevice = !this.device.backendId && !this.device.organizationUnit;
        await this.loadOrganizationUnits();
      }
    },

    async loadOrganizationUnits() {
      const backendId =
        this.selectedBackendId || this.device.backendId || this.device.organizationUnit?.backendId;
      console.log(backendId);
      if (backendId) {
        let response = await PlatformAPI.get(`/api/ou`, {
          params: {
            backendId: backendId,
          },
        });
        this.organizationUnits = response.data;
      }
    },

    async deleteDevice() {
      const confirm = await this.$refs.confirm.open("Delete", "Are you sure?", {
        color: "red",
      });

      if (!confirm) return;

      try {
        var response = await PlatformAPI.delete(`/api/devices/${this.item.id}`);

        this.setSnack({
          message: "Device deleted",
          color: "success",
        });
        //this.$emit("device-deleted");
        this.$router.push("/system");
      } catch (e) {
        this.setSnack({
          message: e,
          color: "error",
        });
      }
    },
  },
};
</script>