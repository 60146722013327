<template>
  <div>
    <v-dialog v-model="showMe" max-width="820" eager persistent no-click-animation>
      <v-card>
        <v-card-title>
          <span class="my-5 text-h5 page-title">Device Terminal</span>
          <v-spacer></v-spacer>

          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on" disabled>
                <i class="nbi-icon nbi-dots"></i>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>

        <v-card-text>
          <span v-if="connecting">
            <v-progress-circular indeterminate size="16" width="2" class="mr-2"></v-progress-circular>connecting..
          </span>

          <div id="terminal-container" ref="terminal"></div>
        </v-card-text>
        <v-card-actions style="justify-content: end; padding: 16px 32px;">
          <v-btn style="text-align: right" outlined class="primary my-2" @click="closeTerminal">Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";

import "xterm/css/xterm.css"; // DO NOT forget importing xterm.css

import { TermColors, SHELL_PROMPT } from "./terminal/constants.js";
import { exec } from "./terminal/commands/index.js";

export default {
  props: {
    device: {
      type: Object,
      default: () => {
        return {};
      }
    },
    startTerminalTrigger: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },

  mounted() {
    console.log("mounted");

    this.$on("terminal-closed", () => {
      this.showMe = false;
    });
  },
  updated() {
    console.log("updated");
    if (!this.showMe) {
      this.$emit("terminal-closed");
      if (this.terminal) this.terminal.dispose();
    }
  },

  data() {
    return {
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" }
      ],
      connecting: true,
      showMe: false,
      terminal: null
    };
  },
  watch: {
    startTerminalTrigger: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.showMe = true;
          this.connecting = true;

          setTimeout(() => {
            this.connecting = false;
            this.startTerminal();
          }, 500);
        }
      }
    }
  },
  methods: {
    closeTerminal() {
      this.showMe = false;
    },
    async startTerminal() {
      if (this.terminal) this.terminal.dispose();
      //const container = document.getElementById("terminal-container");

      this.terminal = new Terminal({
        rendererType: "canvas",
        cursorBlink: "block",
        scrollback: 1000,
        tabStopWidth: 4,
        fontFamily: "CascadiaMono, courier-new, courier, monospace",
        fontSize: 16,
        theme: {
          background: "#060606",
          cursor: "#c7157a",
          selection: "#c7157a",
          cursorAccent: "#c7157a",
          brightMagenta: "#c7157a",
          green: "#2ab025",
          brightGreen: "#2ab025",
          yellow: "#f2ca29",
          brightYellow: "#f2ca29",
          red: "#cf442b",
          brightRed: "#cf442b"
        }
      });

      //   const fitAddon = new FitAddon();
      //   this.terminal.loadAddon(fitAddon);

      this.terminal.focus();

      this.terminal.open(this.$refs["terminal"]);
      //fitAddon.fit();
      // Default text to display on terminal.

      this.terminal.write(SHELL_PROMPT());

      this.terminal.onKey(this.createOnKeyHandler(this.terminal));
      this.terminal.focus();
    },

    loadCommandHistory() {
      const data = localStorage.getItem("history");
      if (!data) {
        return [];
      }
      try {
        return JSON.parse(data);
      } catch {
        return [];
      }
    },
    createOnKeyHandler(term) {
      // Track the user input
      let userInput = "";
      // Track command history
      let commandHistory = this.loadCommandHistory();
      let currentHistoryPosition = commandHistory.length;

      let self = this;
      let d = this.device;

      return async ({ key, domEvent: ev }) => {
        const printable = !ev.altKey && !ev.altGraphKey && !ev.ctrlKey && !ev.metaKey;

        if (ev.keyCode === 9 || ev.keyCode == 37 || ev.keyCode == 39) {
          return;
        }

        if (ev.ctrlKey && ev.key === "c") {
          this.prompt(term);
          userInput = "";
          currentHistoryPosition = commandHistory.length;
          return;
        }

        if (ev.ctrlKey && ev.key === "l") {
          term.clear();
          return;
        }

        // Delete char
        if (ev.keyCode == 8) {
          if (userInput.length > 0) {
            if (term._core.buffer.x === 0 && term._core.buffer.y > 1) {
              // Move up
              term.write("\x1b[A");
              // Move to the end of line
              term.write("\x1b[" + term._core.buffer._cols + "G");
              term.write(" ");
            } else {
              term.write("\b \b");
            }

            userInput = userInput.substring(0, userInput.length - 1);
          }
          return;
        }

        // Run command enter
        if (ev.keyCode == 13) {
          userInput = userInput.trim();
          if (userInput.length === 0) {
            userInput = "";
            this.prompt(term);
            return;
          }

          term.writeln("");
          const result = await exec(userInput, term, self, d);
          if (!result) {
            this.commandNotFound(term);
          }
          if (commandHistory.length > 100) {
            commandHistory = commandHistory.slice(100 - commandHistory.length);
          }
          this.pushToCommandHistory(commandHistory, userInput);
          currentHistoryPosition = commandHistory.length;
          this.prompt(term);
          userInput = "";
          return;
        }

        // Up in the history
        if (ev.keyCode == 38) {
          if (commandHistory.length > 0) {
            currentHistoryPosition = Math.max(0, currentHistoryPosition - 1);
            this.deleteCurrentInput(term, userInput);
            userInput = commandHistory[currentHistoryPosition];
            term.write(userInput);
          }
          return;
        }

        // Down in the history
        if (ev.keyCode == 40) {
          if (commandHistory.length > 0) {
            currentHistoryPosition = Math.min(commandHistory.length, currentHistoryPosition + 1);

            this.deleteCurrentInput(term, userInput);
            if (currentHistoryPosition === commandHistory.length) {
              userInput = "";
              term.write(userInput);
            } else {
              userInput = commandHistory[currentHistoryPosition];
              term.write(userInput);
            }
          }
          return;
        }

        if (printable) {
          term.write(key);
          userInput += key;
        }
      };
    },
    commandNotFound(term) {
      term.writeln(TermColors.Red + 'Command not found. Type "help" to list available commands');
    },

    pushToCommandHistory(store, command) {
      // Avoid duplicates with last command
      if (store.length > 0 && store[store.length - 1] === command) {
        return;
      }
      store.push(command);
      localStorage.setItem("history", JSON.stringify(store));
    },
    prompt(term) {
      term.write("\r\n" + SHELL_PROMPT());
    },

    deleteCurrentInput(term, input) {
      let i = 0;
      while (i < input.length) {
        term.write("\b \b");
        i++;
      }
    }
  }
};
</script>
