<template>
  <v-container style="height: 100%" class="pa-0" fluid>
    <v-overlay :absolute="true" :value="offline || loading" style="z-index: 1100">
      <v-card class="pa-5" light v-if="offline">
        <v-card-text>
          <h3>Disconnected</h3>
          <p>The connection to the server was lost. Please try again after a cup of coffee</p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn outlined class="primary" @click="connectUIHub">Reconnect</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="pa-5" light v-if="loading">
        <v-card-text>
          <div>
            <v-progress-circular
              indeterminate
              :size="20"
              :width="2"
              color="primary"
              class="mr-2"
            ></v-progress-circular>
            Digging up details...
          </div>
        </v-card-text>
      </v-card>
    </v-overlay>

    <l-map
      :center="center"
      ref="map"
      :maxZoom="10"
      :minZoom="2"
      :options="{
        preferCanvas: true,
      }"
    >
      <l-control-fullscreen
        position="topleft"
        :options="{
          title: { false: 'Toggle Fullscreen', true: 'Exit Fullscreen' },
        }"
      />
      <l-tile-layer
        :url="url"
        :noWrap="true"
        :options="{
          bounds: [
            [-90, -180],
            [90, 180],
          ],
        }"
      ></l-tile-layer>

      <v-marker-cluster
        :options="clusterOptions"
        ref="clusterOnline"
        :averageCenter="true"
        :ignoreHidden="true"
      >
        <organization-unit
          v-for="ou in onlineOrganizationUnits"
          :key="ou.id"
          :ou="ou"
        ></organization-unit>
      </v-marker-cluster>

      <v-marker-cluster
        :options="clusterOptions"
        ref="clusterOffline"
        :averageCenter="true"
        :ignoreHidden="true"
      >
        <organization-unit
          v-for="ou in offlineOrganizationUnits"
          :key="ou.id"
          :ou="ou"
          :pulse="true"
        ></organization-unit>
      </v-marker-cluster>
    </l-map>
  </v-container>
</template>
<script>
import { latLng, icon, divIcon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { HubConnectionBuilder } from "@microsoft/signalr";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import OrganizationUnit from "./device-map-ou";
import LControlFullscreen from "vue2-leaflet-fullscreen";

import { PlatformAPI, WSAPI } from "../http";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = "";
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("@/assets/pin_offline@1x.png"),
  iconUrl: require("@/assets/pin_offline@1x.png"),
  shadowUrl: "",
  iconSize: [35, 57],
  iconAnchor: [17, 57],
  status: "default",
});

const defaultIcon = icon();

const greenIcon = icon({
  iconUrl: require("@/assets/pin_default@1x.png"),
  status: "green",
  iconSize: [35, 57],
  iconAnchor: [17, 57],
});
const identifyingIcon = icon({
  iconUrl: require("@/assets/pin_identifying@1x.png"),
  status: "identifying",
  iconSize: [35, 57],
  iconAnchor: [17, 57],
});
const warningIcon = icon({
  iconUrl: require("@/assets/pin_error@1x.png"),
  iconSize: [42, 68],
  iconAnchor: [21, 34],
  status: "warning",
});

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    OrganizationUnit,
    LControlFullscreen,
  },
  computed: {
    onlineOrganizationUnits() {
      return this.organizationUnits.filter((o) => o.state != "disconnected");
    },
    offlineOrganizationUnits() {
      return this.organizationUnits.filter((o) => o.state == "disconnected");
    },
    groupedOrganizationUnits() {
      var x = groupBy(this.organizationUnits, (ou) => ou.countryId);
      return Array.from(x, ([countryId, organizationUnits]) => ({
        countryId,
        organizationUnits,
      }));
    },
  },
  data() {
    return {
      loading: true,
      connection: null,
      offline: false,
      clusterOptions: {
        maxClusterRadius: 50,

        iconCreateFunction: (cluster) => {
          var markers = cluster.getAllChildMarkers();

          var grouped = groupBy(markers, (i) => i.options.ou.state);

          if (grouped.size == 1) {
            if (grouped.keys().next().value == "connected") {
              return greenIcon;
            } else if (grouped.keys().next().value == "disconnected") {
              return warningIcon;
            } else {
              return defaultIcon;
            }
          } else {
            return warningIcon;
          }
        },
      },

      selectedBackendId: null,

      backends: [],
      organizationUnits: [],
      center: latLng(47.41322, -1.219482),
      // url: "https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
    };
  },
  mounted() {
    var self = this;

    this.connection = new HubConnectionBuilder()
      .withUrl(WSAPI.url() + `/ws/${WSAPI.version()}/ui`, {
        accessTokenFactory: () => self.$store.state.authenticated_user.token,
      })
      .build();

    this.connection.on("StatusUpdate", (data) => {
      self.updateNode(data);
      self.$refs.clusterOnline.mapObject.refreshClusters();
      self.$refs.clusterOffline.mapObject.refreshClusters();

      if (this.offlineOrganizationUnits.length) {
        this.$refs.map.mapObject.fitBounds(
          this.offlineOrganizationUnits.map((m) => {
            return m.coordinates;
          })
        );
      } else {
        this.$refs.map.mapObject.fitBounds(
          this.organizationUnits.map((m) => {
            return m.coordinates;
          })
        );
      }
    });

    this.connection.onclose(async () => {
      this.offline = true;
    });

    this.connectUIHub();
  },
  methods: {
    connectUIHub() {
      this.offline = false;
      this.connection.start().then(() => {
        console.log("connection started");

        this.loadData();
      });
    },
    updateNode(data) {
      var self = this;

      console.log(data);
      if (data.organizationUnits) {
        data.organizationUnits.forEach((o) => {
          var element = self.organizationUnits.filter((x) => x.id == o);
          if (element.length) {
            element[0].state = data.state;
          }
        });
      }
    },

    async loadData() {
      try {
        this.loading = true;

        PlatformAPI.get("/api/backends").then(async (response) => {
          if (response && response.data) this.backends = response.data;
          if (this.backends.length && this.selectedBackendId == null)
            this.selectedBackendId = this.backends[0].id;

          var data = await this.connection.invoke("GetMap", this.selectedBackendId);
          this.organizationUnits = data;

          this.$refs.map.mapObject.fitBounds(
            this.organizationUnits.map((m) => {
              return m.coordinates;
            })
          );
          this.loading = false;
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      } finally {
      }
    },
  },
};
</script>
