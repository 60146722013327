
export default {
    methods: {
        formatDate(value) {
            if ((value || '') == '')
                return '';

            if (!value.endsWith('Z'))
                value += "Z";
            return new Date(value).toLocaleDateString(
                'nl-nl', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }
            );
        },
        formatDateTime(value) {
            if ((value || '') == '')
                return '';

            if (!value.endsWith('Z'))
                value += "Z";
            return new Date(value).toLocaleTimeString(
                'nl-nl', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }
            );
        },
        formatOUName(item) {

            if (!item)
                return '';

            if (item.backendInstanceId)
                return item.backendInstanceId + " - " + item.name;
            else
                return item.name;
        },

        formatCountry(countryId) {
            if (countryId == null) return null;
            var result = this.$store.state.countries.find(c => c.code == countryId);
            return result && result.name;
        },

    }
}