<template>
  <l-marker :key="ou.id" :options="{ ou: ou }" :lat-lng="ou.coordinates">
    <l-icon
      ref="icon"
      :icon-url="getIcon(ou)"
      :shadow-url="null"
      :iconSize="getIconSize()"
      :iconAnchor="[17, 57]"
    >
    </l-icon>
    <l-popup>
      <h3>{{ ou.name }}</h3>
      <p>{{ ou.remoteId }}</p>
      <router-link
        :to="{ name: 'system', params: { type: 'ou', id: `${ou.id}` } }"
        >details</router-link
      >
    </l-popup>
  </l-marker>
</template>

<script>
import { LIcon, LMarker, LPopup } from "vue2-leaflet";

export default {
  components: { LIcon, LMarker, LPopup },
  props: {
    ou: {
      type: Object,
      required: true,
    },
    pulse: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    getIconSize() {
      return this.pulse ? [42, 68] : [35, 57];
    },
    getIcon(ou) {
      if (ou.state == "connected") {
        return require("@/assets/pin_default@1x.png");
      } else if (ou.state == "disconnected") {
        return require("@/assets/pin_error@1x.png");
      } else {
        return require("@/assets/pin_offline@1x.png");
      }
    },
  },
};
</script>
