<template>
  <div style="max-width: 1600px">
    <h2 class="mb-5">
      System Overview
      <v-spacer></v-spacer>
    </h2>

    <v-row justify="space-between">
      <v-col>
        <v-card class="pb-5" outlined>
          <v-card-title>
            Devices
            <v-spacer></v-spacer>
            <v-btn to="/map" outlined color="secondary" class="v-btn-icon float-right ml-2">
              <i class="nbi-icon nbi-network"></i>
            </v-btn>
            <v-btn
              outlined
              class="v-btn-icon float-right ml-2"
              color="secondary"
              to="/devices/new"
              v-if="$isAdmin"
            >
              <i class="nbi-icon nbi-plus"></i>
            </v-btn>
          </v-card-title>
          <v-data-table
            :items="filteredDevices"
            item-key="id"
            :headers="columns"
            :items-per-page="20"
            :footer-props="{
              'disable-items-per-page': true,
            }"
            :loading="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="row-pointer"
          >
            <template v-slot:item.state="{ item }">
              <i
                class="nbi-icon nbi-sentinel"
                :class="deviceIcon(item) + ' ' + deviceColor(item)"
              ></i>
            </template>

            <template v-slot:item.name="{ item }">
              <router-link :to="{ name: 'device', params: { type: 'id', id: `${item.id}` } }">
                {{ item.name }}
              </router-link>
            </template>

            <template v-slot:item.organizationUnit="{ item }">
              <span v-if="item.organizationUnit">{{ item.organizationUnit.name }}</span>
              <span v-else-if="item.isFallback">
                <i class="secondary--text">fallback</i>
              </span>
              <span v-else></span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col class="col-lg-3">
        <v-card class="pb-5" outlined>
          <v-card-title>Filters</v-card-title>

          <v-card-text>
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="nbi-icon nbi-search"
              label="Search..."
              flat
              outlined
              solo
              hide-details
              clearable
              clear-icon="nbi-icon nbi-xmark"
              class="mb-5 no-label"
            ></v-text-field>

            <h4>Device State</h4>

            <v-radio-group v-model="filters.connectionState">
              <v-radio :key="-1" value="all" label="All"></v-radio>
              <v-radio
                v-for="(item, index) in connectionStates"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></v-radio>
            </v-radio-group>

            <h4>Device Type</h4>

            <v-radio-group v-model="filters.deviceType">
              <v-radio :key="-1" value="all" label="All"></v-radio>
              <v-radio
                v-for="(item, index) in deviceTypes"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></v-radio>
            </v-radio-group>
            <v-autocomplete
              label="Country"
              v-model="filters.country"
              clearable
              outlined
              no-data-text="No countries found"
              :items="countries"
              item-value="code"
              item-text="name"
            ></v-autocomplete>

            <v-select
              v-if="backends.length > 1"
              outlined
              label="Backend"
              :items="backends"
              item-value="id"
              item-text="name"
              v-model="filters.backend"
              append-icon="nbi-icon nbi-chevron-down"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showDeviceEditor" width="600">
      <DeviceEditorCard v-bind:item="selected" v-on:close-dialog="closeDialog"></DeviceEditorCard>
    </v-dialog>
  </div>
</template>
<style>
.v-treeview-node {
  cursor: pointer;
}
</style>

<script>
import { PlatformAPI, WSAPI } from "../http";

import DeviceEditorCard from "./device-list-device";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { mapState } from "vuex";

var AlwaysReconnectPolicy = (function () {
  function AlwaysReconnectPolicy() {}

  AlwaysReconnectPolicy.prototype.nextRetryDelayInMilliseconds = function (retryContext) {
    return retryContext.elapsedMilliseconds < 60000 ? 2500 : 15000;
  };

  return AlwaysReconnectPolicy;
})();

const shallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every((key) => obj1[key] === obj2[key]);

export default {
  components: {
    DeviceEditorCard,
  },
  data() {
    return {
      connection: null,
      loading: false,
      backends: [],

      filters: {
        country: null,
        search: "",
        deviceType: "all",
        connectionState: "all",
        backend: null,
        backend: null,
      },

      connectionStates: [
        // { value: "identifying", name: "Identifying" },
        { value: "connected", name: "Connected" },
        { value: "disconnected", name: "Disconnected" },
      ],

      deviceTypes: [
        { value: "unassigned", name: "Unassigned" },
        { value: "assigned", name: "Assigned" },
        { value: "fallback", name: "Fallback" },
      ],

      selected: null,
      showDeviceEditor: false,
      devices: [],
      columns: [
        { text: "State", value: "state", width: "85px", align: "center" },
        { text: "Name", value: "name" },
        { text: "Organization Unit", value: "organizationUnit" },
        { text: "Country", value: "organizationUnit.country.name" },
        { text: "Serial #", value: "serialNumber" },
        { text: "Client Version", value: "clientVersion" },
      ],
      sortBy: "state",
      sortDesc: false,
    };
  },
  watch: {
    filters: {
      handler: function (newVal) {
        if (this.loading) return;

        let newQueryParams = {
          ...(newVal.country && { country: newVal.country }),
          ...(newVal.search && { q: newVal.search }),
          ...(newVal.deviceType && { dt: newVal.deviceType }),
          ...(newVal.connectionState && { cs: newVal.connectionState }),
          ...(newVal.backend && { backend: newVal.backend }),
        };

        console.log(this.$route.query, newQueryParams);
        if (shallowCompare(newQueryParams, this.$route.query)) return;

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            ...(newVal.country && { country: newVal.country }),
            ...(newVal.search && { q: newVal.search }),
            ...(newVal.deviceType && { dt: newVal.deviceType }),
            ...(newVal.connectionState && { cs: newVal.connectionState }),
            ...(newVal.backend && { backend: newVal.backend }),
          },
        });
      },
      deep: true,
    },
  },

  async created() {
    var self = this;

    this.filters.backend = this.$route.query.backend;
    this.filters.search = this.$route.query.q;
    this.filters.country = this.$route.query.country;
    this.filters.deviceType = this.$route.query.dt || "all";
    this.filters.connectionState = this.$route.query.cs || "all";
    this.filters.backend = Number(this.$route.query.backend);
    (isNaN(this.filters.backend) || this.filters.backend == 0) && (this.filters.backend = null);

    this.connection = new HubConnectionBuilder()
      .withUrl(WSAPI.url() + `/ws/${WSAPI.version()}/ui`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => self.$store.state.authenticated_user.token,
      })
      .withAutomaticReconnect(new AlwaysReconnectPolicy())
      .build();

    this.connection.on("StatusUpdate", (data) => self.updateDevice(data));

    this.connection
      .start()
      .then(() => console.log("connected"))
      .catch((err, a, b) => {
        // console.error(err);
        // debugger;

        // if (err.statusCode == 401) {
        self.$store.commit("setLoggedInUser", null);

        self.$router.push({
          name: "login",
        });
        //}
      });
  },

  async mounted() {
    console.log("mounted");

    await this.refresh();
  },

  beforeDestroy() {
    this.connection && this.connection.stop();
  },
  computed: {
    ...mapState(["countries"]),
    filteredDevices() {
      return this.devices.filter((item) => {
        // return item.serialNumber == "A123456" || item.name =='test1000';
        return (
          (this.filters.connectionState == "all" || this.filters.connectionState == item.state) &&
          (this.filters.deviceType == "all" ||
            (item.isFallback && this.filters.deviceType == "fallback") ||
            (this.filters.deviceType == "assigned" &&
              !item.isFallback &&
              item.organizationUnit != null) ||
            (this.filters.deviceType == "unassigned" &&
              !item.isFallback &&
              item.organizationUnit == null)) &&
          (!this.filters.country ||
            (item.organizationUnit && this.filters.country == item.organizationUnit.countryId)) &&
          (!this.filters.search ||
            (item.backendInstanceId && item.backendInstanceId.indexOf(this.filters.search) > -1) ||
            (item.serialNumber && item.serialNumber.indexOf(this.filters.search) > -1) ||
            (item.managementId && item.managementId.indexOf(this.filters.search) > -1) ||
            (item.name &&
              item.name.toLowerCase().indexOf(this.filters.search.toLowerCase()) > -1) ||
            (item.organizationUnit &&
              item.organizationUnit.name.toLowerCase().indexOf(this.filters.search.toLowerCase()) >
                -1) ||
            (item.id && item.id.indexOf(this.filters.search) > -1))
        );
      });
    },
  },

  methods: {
    closeDialog() {
      this.showDeviceEditor = false;
      this.selected = null;
    },
    updateDevice(data) {
      console.log(data);
      var device = this.devices.filter((x) => x.id == data.deviceId);
      if (device.length) device[0].state = data.state;
    },
    async deviceUpdated() {
      await this.refresh();
    },
    async deviceDeleted() {
      this.active = [];

      const route = this.$router.resolve({
        name: "system",
      });
      history.replaceState(null, "", route.href);
      await this.refresh();
    },

    deviceIcon(item) {
      return item.isFallback ? "nbi-sentinel-global" : "nbi-sentinel";
    },

    deviceColor(item) {
      if (item.state == "connected") return "green--text";

      if (item.state == "disconnected") return "secondary--text";
      if (item.state == "identifying") return "yellow--text";

      return null;
    },
    async refresh() {
      try {
        this.loading = true;
        console.log("refresh");

        await PlatformAPI.get("/api/backends").then((response) => {
          if (response && response.data) this.backends = response.data;
          console.log(this.filters);
          if (this.backends.length && this.filters.backend == null) {
            console.log("setting default backend filter");
            this.filters.backend = this.backends[0].id;
          }

          PlatformAPI.get(`/api/system/overview`, {
            params: { backendId: this.filters.backend },
          }).then(
            (response) => {
              if (response && response.data) this.devices = response.data;
            },
            () => {
              this.devices = [];
            }
          );
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

