import Vuex from "vuex";
import Vue from "vue";
import { PlatformAPI, AuthenticationPlugin } from "../http";

import pjson from "@/../package.json";

Vue.use(Vuex);

const navItems = [
  {
    icon: "nbi-overview",
    text: "System",
    route: "/system",
  },
  {
    icon: "nbi-eva",
    text: "Backends",
    route: "/backends",
  },
  {
    icon: "nbi-user",
    text: "Users",
    route: "/users",
  },
  {
    icon: "nbi-gear",
    text: "Settings",
    route: "/settings",
  },
];

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const state = {
  uiVersion: process.env.NODE_ENV == "development" ? "develop" : pjson.version,
  environment: process.env.NODE_ENV,
  version: null,
  countries: [],
  platform: {
    backends: 0,
  },
  authenticated_user: null,
  snackbar: {
    message: "",
    color: "success",
  },
  offline: false,
};

Object.defineProperty(Vue.prototype, "$isAdmin", {
  get() {
    return this.$store.getters.isAdmin;
  },
});

const store = new Vuex.Store({
  plugins: [AuthenticationPlugin],
  state,
  getters: {
    navigationItems: (state) => {
      return state.authenticated_user ? navItems : [];
    },
    isAdmin() {
      return state.authenticated_user && state.authenticated_user.role == "Admin";
    },
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    },
    setLoggedInUser(state, payload) {
      state.authenticated_user = payload;

      if (state.authenticated_user) {
        var jwt = parseJwt(state.authenticated_user.token);
        state.authenticated_user.role = jwt.role;
        localStorage.setItem("user", JSON.stringify(payload));
      } else {
        localStorage.removeItem("user");
      }
    },
    setPlatformInformation(state, info) {
      state.platform = info;
    },
    setSnack(state, { message, color }) {
      state.snackbar = {
        message,
        color,
      };
    },
  },

  actions: {
    logout({ commit }) {
      commit("setLoggedInUser");
    },
    async authenticate({ commit }, user) {
      var response = await PlatformAPI.post(`/api/users/authenticate`, user);
      if (response.data) {
        commit("setLoggedInUser", response.data);
        await this.dispatch("refreshPlatformInformation");
        return response.data;
      } else {
        debugger
        return null;
      }
    },

    async refreshPlatformInformation({ commit }) {
      try {
        var platformResponse = await PlatformAPI.get(`/api/info`);
        if (platformResponse && platformResponse.data) {
          commit("setPlatformInformation", platformResponse.data);
          return platformResponse.data;
        }
        return null;
      } catch (error) {
        console.log(error);
      }
    },
    async refreshLookups({ commit }) {
      var tasks = [];

      tasks.push(PlatformAPI.get(`/api/dd/countries`));

      return Promise.all(tasks).then(
        (results) => {
          const [countries] = results;
          if (countries) commit("setCountries", countries.data);
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
});

try {
  var loggedInUser = JSON.parse(localStorage.getItem("user"));
  store.commit("setLoggedInUser", loggedInUser);
} catch (e) {
  store.commit("setLoggedInUser", null);
}

export default store;
