<template>
  <v-app>
    <v-app-bar height="52" app flat clipped-left style="border-bottom: 1px solid #d5dadf">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="isMobile && navigationEnabled"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="product-title">
        <router-link to="/" v-if="navigationEnabled">
          <img src="@/assets/eva.svg" class="eva-logo mr-5" />
        </router-link>
        <img v-else src="@/assets/eva.svg" class="eva-logo mr-5" />
        Watchtower I/O
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <small v-if="!navigationEnabled">version: {{ platform.version }}</small>
      <v-btn icon color="secondary" @click="logout" class="v-btn-icon" v-if="loggedIn && !offline">
        <i class="nbi-icon nbi-exit"></i>
      </v-btn>
    </v-app-bar>

    <!-- permanent
      :mini-variant="!drawer"
    mini-variant-width="70"-->
    <v-navigation-drawer
      app
      clipped
      v-model="drawer"
      floating
      stateless
      v-if="navigationEnabled"
      :permanent="$vuetify.breakpoint.lgAndUp"
    >
      <!-- https://github.com/vuetifyjs/vuetify/issues/8007 -->
      <v-list shaped class="primary--text">
        <v-list-item-group>
          <v-list-item
            v-for="(item, index) in navigationItems"
            :key="index"
            :to="item.route"
            class="justify-start pl-5"
          >
            <v-list-item-icon>
              <i class="nbi-icon" :class="item.icon"></i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <offline v-if="offline" class="pa-5"></offline>
      <router-view v-else class="pa-5"></router-view>
      <!-- :key="$route.fullPath" -->
    </v-main>

    <Snackbar />
    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-app>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import Snackbar from "@/components/snackbar";
import Offline from "@/components/offline";

export default {
  name: "App",
  components: {
    Snackbar,
    Offline,
  },
  data() {
    return {
      drawer: false,
    };
  },

  computed: {
    ...mapState(["loading", "environment", "version", "setup", "platform"]),
    ...mapGetters(["navigationItems"]),
    fullScreen() {
      return this.$route.meta && this.$route.meta.fullscreen;
    },
    navigationEnabled() {
      return this.offline || (this.$route.meta && this.$route.meta.navigationEnabled === false)
        ? false
        : true;
    },
    loggedIn() {
      return this.$store.state.authenticated_user;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },

    offline() {
      return this.$store.state.offline;
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      console.log("logged out");
      this.$router.push("/login");
    },
    setTab(index) {
      alert(index);
    },
    wrapAction(action) {
      action({ $router: this.$router, $store: this.$store });
    },
  },
};
</script>


<style lang="scss">
@font-face {
  font-family: "New Black Icons";
  src: url("~@/assets/New-Black-Symbols.ttf") format("truetype");

  font-weight: normal;
  font-style: normal;
}

.nbi-icon {
  font-size: 28px;
  font-family: "New Black Icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  position: relative;

  justify-content: center;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.nbi-icon.small {
  font-size: 18px;

  margin-left: -4px;
  margin-right: 8px;
}

.nbi-search::before {
  content: "\e900";
}
.nbi-document::before {
  content: "\e901";
}
.nbi-duplicate::before {
  content: "\e902";
}
.nbi-folder::before {
  content: "\e903";
}
.nbi-folder-marker::before {
  content: "\e904";
}
.nbi-folder-star::before {
  content: "\e905";
}
.nbi-calendar::before {
  content: "\e906";
}
.nbi-plus-circle::before {
  content: "\e907";
}
.nbi-minus-circle::before {
  content: "\e908";
}
.nbi-popout::before {
  content: "\e909";
}
.nbi-trashcan::before {
  content: "\e90a";
}
.nbi-gear::before {
  content: "\e90b";
}
.nbi-information::before {
  content: "\e90c";
}
.nbi-warning::before {
  content: "\e90d";
}

.nbi-arrow-left::before {
  content: "\e90e";
}
.nbi-arrow-right::before {
  content: "\e90f";
}
.nbi-arrow-first::before {
  content: "\e910";
}
.nbi-arrow-last::before {
  content: "\e911";
}
.nbi-chevron-left::before {
  content: "\e912";
}
.nbi-chevron-right::before {
  content: "\e913";
}
.nbi-chevron-up::before {
  content: "\e914";
}
.nbi-chevron-down::before {
  content: "\e915";
}
.nbi-check::before {
  content: "\e916";
}
.nbi-plus::before {
  content: "\e917";
}
.nbi-dragmove::before {
  content: "\e919";
}
.nbi-exit::before {
  content: "\e91a";
}
.nbi-shuffle::before {
  content: "\e91b";
}
.nbi-return::before {
  content: "\e91c";
}
.nbi-upload::before {
  content: "\e91d";
}
.nbi-download::before {
  content: "\e91e";
}
.nbi-sentinel::before {
  content: "\e91f";
}
.nbi-sentinel-offline::before {
  content: "\e96b";
}
.nbi-sentinel-error::before {
  content: "\e96a";
}
.nbi-sentinel-global::before {
  content: "\e96c";
}
.nbi-shopping-bag::before {
  content: "\e920";
}
.nbi-shopping-bag-stars::before {
  content: "\e921";
}
.nbi-shopping-bag-check::before {
  content: "\e922";
}
.nbi-store::before {
  content: "\e923";
}
.nbi-stores::before {
  content: "\e924";
}
.nbi-office::before {
  content: "\e925";
}
.nbi-warehouse::before {
  content: "\e926";
}
.nbi-bank::before {
  content: "\e927";
}
.nbi-printer::before {
  content: "\e928";
}
.nbi-money::before {
  content: "\e929";
}
.nbi-clock::before {
  content: "\e930";
}
.nbi-openinghours-templates::before {
  content: "\e931";
}
.nbi-overview::before {
  content: "\e932";
}
.nbi-creditcard::before {
  content: "\e92a";
}
.nbi-creditcard-gear::before {
  content: "\e92b";
}
.nbi-bike-courier::before {
  content: "\e92f";
}
.nbi-receipt::before {
  content: "\e934";
}
.nbi-user::before {
  content: "\e92c";
}

.nbi-user-check::before {
  content: "\e92e";
}

.nbi-user-list::before {
  content: "\e92d";
}
.nbi-eva::before {
  content: "\e949";
}
.nbi-network::before {
  content: "\e944";
}
.nbi-sidebar-left::before {
  content: "\e947";
}
.nbi-sidebar-right::before {
  content: "\e946";
}
.nbi-edit::before {
  content: "\e945";
}
.nbi-cloud-sync::before {
  content: "\e94a";
}

.nbi-expand::before {
  content: "\e961";
}

.nbi-collapse::before {
  content: "\e962";
}

.nbi-dots::before {
  content: "\e941";
}

.nbi-eye::before {
  content: "\e966";
}
.nbi-eye-slash::before {
  content: "\e965";
}
.nbi-xmark::before {
  content: "\e918";
}
.nbi-terminal::before {
  content: "\e96d";
}

.v-text-field:not(.no-label) {
  input,
  .v-select__selections:not(.v-data-footer .v-select__selections) {
    padding-top: 16px !important;
    padding-bottom: 4px !important;
  }

  &.v-text-field--outlined .v-label.v-label--active {
    top: 32px;
  }
  legend {
    width: 0px !important;
  }
}

// .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item,
// .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item::before,
// .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item > .v-ripple__container {
//   border-bottom-right-radius: 10px !important;
//   border-top-right-radius: 10px !important;
// }

// .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar),
// .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar) {
//   border-radius: $text-field-border-radius !important;
// }

.theme--light {
  .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: 36px;
  }
  .v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn .v-icon {
    color: var(--v-primary-base) !important;
  }

  .v-btn:not(.v-btn--round):not(.v-btn--text):not(.v-btn-toggle
      .v-btn):not(.v-btn-icon).v-size--default {
    padding-left: 24px;
    padding-right: 24px;
    min-width: 128px;
  }

  //   min-width: 128px;
  //   .v-btn__content {
  //     i:not(.nbi-icon) {
  //       margin-left: 24px;
  //       margin-right: 24px;
  //     }
  //   }
  // }
  .v-toolbar {
    .v-btn:not(:first-child) {
      margin-left: 12px;
    }
  }
  .v-btn {
    &.v-btn--is-elevated:not(:active):not(:disabled),
    &.v-btn--outlined:not(:active):not(:disabled) {
      box-shadow: 0px 2px 3px 0px #14202d1f !important;
    }

    // background: white !important;
    &:not(.v-app-bar__nav-icon) {
      .v-btn__content {
        color: var(--v-primary-base) !important;
        &.error {
          color: var(--v-error-base) !important;
        }
      }
    }

    &:hover {
      background: #f7f8f9 !important;

      .v-btn__content {
        color: #0053ae !important;
      }
    }

    &.primary {
      &:not(:disabled) {
        background: linear-gradient(180deg, #1283ff 0%, #007aff 100%) !important;
        color: white !important;
      }
      .v-btn__content {
        color: white !important;
        font-size: 15px;
      }

      &:hover {
        background: linear-gradient(180deg, #0d75e7 0%, #0066d6 100%) !important;
      }
    }
    &.v-btn--disabled {
      box-shadow: none !important;

      background-color: #fff !important;
      border-color: #f0f2f5 !important;
      .v-btn__content {
        color: #d5dadf !important;
      }
      &.v-btn--has-bg {
        background-color: #f0f2f5 !important;
        .v-btn__content {
          color: #fff !important;
        }
      }
    }

    &.v-btn--plain {
      &:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) {
        .v-btn__content {
          opacity: 1;
        }
      }
    }
  }

  .v-snack--text .v-snack__wrapper:before {
    opacity: 1;
  }

  .v-snack__action .v-btn {
    .v-btn__content i {
      opacity: 0.4;
      &:hover {
        opacity: 1;
      }
    }
    &:hover {
      background-color: transparent !important;
    }
  }

  .row-pointer {
    tbody {
      tr:hover {
        cursor: pointer;
      }
    }
  }
}
</style>